import { ref, computed, watch } from "vue";
import { getData, getItem, saveData, nameSearch, copyModel, setAction} from '@/plugins/OdooService';
import { state, user, hasTag ,hasRole, organisation, accountList, isHospital} from '@/plugins/authService';
import { initProcuration} from '@/plugins/procurationService';
import {getStockItem, setStockQuantity, selectedCabinet,selectedStock, loadStockItem, stockList} from "@/plugins/cabinetControler";
import { needProcuration, hasProcuration, procuration, clearedProcuration, listAccountProcuration, procurationList} from '@/plugins/procurationService';
import {orderCartList, orderQueUe,orderCart, cummulativeOrders} from '@/plugins/orderService';
import {  openToast, loading,showLoading, isConsole, prompt, hideLoading} from "@/plugins/interfaceControler";
import {setCabinet} from  "@/plugins/cabinetControler";
import {scanType} from "@/plugins/scanControler"
import config from "@/config";

export const productList:any = ref([])
export const favoriteList:any = ref([])
export const locationList:any = ref([])
export const suppliers:any = ref([])
export const projectList:any = ref([])
export const investmentList:any = ref([])
export const contractList:any = ref([])
export const categories:any = ref({})
export const productGroup:any = ref([])
export const selectedProduct:any = ref([])
export const invalidProduct:any = ref()
export const alternativeProduct:any = ref()
export const selectedCategories:any = ref([])
export const cart = computed({
  get () {
    if (!state.value.cart) state.value.cart = []
    return state.value.cart
  },
  set (value) {
    state.value.cart = value
  }
})
export const orderTypes= computed({
  get () {
    if (!state.value.types) state.value.types = []
    return state.value.types
  },
  set (value) {
    state.value.types = value
  }
})

export const selectedCatalog = computed({
  get () {
    return state.value.catalog
  },
  set (cat) {
    if (!cat) return state.value.catalog = cat
    state.value.catalog = Object.assign({cart: '/shop/cart', home: '/shop/start'}, cat )
    if (cat.checkout) return false
    if (cat.procuration){
      state.value.catalog.cart = `/shop/checkout`
      state.value.catalog.home = `/shop/request/${cat.value}`
    }
  }
})

export function setCatalog(name:string='catalog', force=false){
  
  const cat = orderTypes.value.filter((c:any)=>c.value==name)[0]
  
  if (!cat || !cat.id) return false
  
  if (!selectedCatalog.value )  selectedCatalog.value = cat
  if (force) return  selectedCatalog.value = cat
  if (cartItemCount.value > 0 && cat.catalog !=selectedCatalog.value.catalog) return false
  
  
  if (selectedCatalog.value ) {
    if (selectedCatalog.value && cartItemCount.value > 0 && cat.catalog != selectedCatalog.value.catalog) return false
    if (selectedCatalog.value.stream && cartItemCount.value > 0) return true //VTA mix orders
    //if (cat.catalog == selectedCatalog.value.catalog) {
      selectedCatalog.value = cat
      return true
    //}
    
  }
  setCatalog()
  //resetValues()
  cat.cart = '/shop/cart'
  cat.home =  `/shop/list`
  if (cat.id && !cat.checkout) {
      cat.cart = `/shop/checkout` //request/${cat.id}`
      cat.home =  `/shop/request{cat.value}`
  }
  selectedCatalog.value = cat
  return true
}
export const orderData = computed({
  get () {
    if (!state.value.order) state.value.order = {}
    return state.value.order
  },
  set (value) {
    state.value.order = value
  }
})
export const location = computed({
  get () {
    return orderData.value.location
  },
  set (value) {
    orderData.value.location = value
  }
})
export const project = computed({
  get () {
    return orderData.value.project
  },
  set (value) {
    orderData.value.project = value
  }
})
export const investment = computed({
  get () {
    return orderData.value.invest
  },
  set (value) {
    orderData.value.invest = value
  }
})
export const contract = computed({
  get () {
    return orderData.value.contract
  },
  set (value) {
    orderData.value.contract = value
  }
})
export const orderNote = computed({
  get () {
    return orderData.value.note
  },
  set (value) {
    orderData.value.note = value
  }
})
export const orderType: any = computed(()=>{
    if (!selectedCatalog.value) return 'Catalogus'
    orderData.value.type = selectedCatalog.value.id
    return selectedCatalog.value.value || selectedCatalog.value.catalog
})
export const account = computed({
  get () {
    return orderData.value.account
  },
  set (value) {
    orderData.value.account = value
  }
})

export const supplier = computed({
  get () {
    return orderData.value.supplier
  },
  set (value) {
    orderData.value.supplier = value
  }
})
export const files:any = ref([])
export const emergency = computed({
  get () {
    return orderData.value.emergency
  },
  set (value) {
    orderData.value.emergency = value
  }
})
export const requestitems = computed({
  get () {
    return state.value.requests || []
  },
  set (value) {
    state.value.requests = value
  }
})
export const orderitems = computed({
  get () {
    return state.value.items || []
  },
  set (value) {
    state.value.items = value
  }
})
export const lastitem = computed({
  get () {
    return state.value.last || 'vta'
  },
  set (value) {
    state.value.last = value
  }
})
export const favorites = computed({
  get () {
    return state.value.favorites || []
  },
  set (value) {
    state.value.favorites = value
    getFavorites()
  }
})
export const cartCount = computed(()=>{
  let count = 0
  if (!state.value.cart) state.value.cart = []
  state.value.cart.map((c:any)=>count += c.quantity || 1)
  return count
})
 export const cartItemCount = computed(()=>{
   let count = 0
   if (!state.value.cart) state.value.cart = []
   state.value.cart.map((c:any)=>count += c.quantity || 1)
   orderitems.value.map((c:any)=>count += c.quantity || 1)
   requestitems.value.map((c:any)=>count += c.quantity || 1)
   return count
 })
 export const cartItems = computed(()=>{
  let count = state.value.cart.length// + orderitems.value.length
  return count
})
 export const cartAmount = computed(()=>{
  let amount = 0
  state.value.cart.map((c:any)=>amount += (c.quantity || 0) * c.x_list_price)
  return amount
})
export const cartVat = computed(()=>{
  let amount = 0
  state.value.cart.map((c:any)=>{
    const tax = config.tax.filter((t:any)=>t.id==c.x_taxes_id)[0] || {value: 21}
    amount += (c.quantity || 0) * c.x_list_price * ((tax.value)/100)
  })
  return amount
})
export const preCartItemCount = computed(()=>{ 
  let count = 0
  orderitems.value.map((c:any)=>count += c.quantity || 1)
  return count
})
export const preCartAmount = computed(()=>{
  let amount = 0
  orderitems.value.map((c:any)=>amount += (c.quantity || 0) * c.list_price)
  return amount
})
export const preCartVat = computed(()=>{
  let amount = 0
  orderitems.value.map((c:any)=>amount += (c.quantity || 0) * c.list_price * ((c.vat||21)/100))
  return amount
})
export const listSuppliers:any = ref([])
export const listTags:any = ref([])

export function inCart(item:any){
  if (!item) return false
  const cab = item.x_cabinet_id //selectedCabinet.value? selectedCabinet.value.id :
  const p = state.value.cart.filter((c:any)=>c.x_product_id==item.x_product_id && (!c.x_cabinet_id || c.x_cabinet_id==cab))[0]
  return p ? p : false
}

export function getCartItem(index:any){
  const p = state.value.cart.filter((p:any)=>p.x_product_id == index)[0] || (state.value.items && state.value.items.filter((p:any)=>p.x_product_id == index || p.id == index)[0])
  
  return p
}

export function updateCartItem(product:any){
  state.value.cart.filter((p:any)=>p.id == product.id).map((p:any)=>{
    p.quantity =product.quantity 
    p.extra_note =product.extra_note
  })
}



export async  function listMenu(options:any){
  options.group = ["x_category", "x_category_sub"]
  const menu = await getData('catalog/list', options)
  menu.map((m:any)=>{
    const cat = m.x_category ? m.x_category : 'Geen'
    const sub = m.x_category_sub
    if(!categories.value[cat]) categories.value[cat] = {}
    if(!categories.value[cat][sub]) categories.value[cat][sub] = 0
    categories.value[cat][sub]++
  })
}
export async function listProducts(options: any, list=false, all=false){ //{q:string, tags:any=null}
  options.hospital = organisation.value.id
  if (options.q) options.top =20
  const result = await getData(all ? 'catalog/all' : 'catalog/list',options)
  if (list) listMenu(options)
  return result
}
export async function searchProducts(q:string, tags:any=null){
  if (!q) return await initCatalog()
  let isnum = /^\d+$/.test(q);
  productList.value =await listProducts({q: isnum ? '-' + q : q, tags})
  return productList.value
}
export async function shortSearchProducts(q:string){
  if (!q || q.length < 2) return []
  let isnum = /^\d+$/.test(q);
  const ret = await listProducts({q: isnum ? '-' + q : q, short:true})
  return ret
}

export async function lookupProducts(barcode:string){
  const list = await listProducts({barcode})
  
  return list
}
export async function lookupSupplier(name: string){
  const list = await getData('catalog/supplier/' + name)
  return list
}
export async function lookupProject(name: string){
  const list = await nameSearch('project.project',name)
  return list
}
export async function lookupUnit(name: string){
  const list = await nameSearch('uom.uom',name)
  return list.filter((l:any)=>l.name==name)
}
export async function copyProduct(index: number){
  const list = await copyModel('product.product',index)
  return list
}

export async function listProductGroup(q?:Number){
  
  productGroup.value = await getData('admin/account/list/productgroup',{hospital: organisation.value.id,q})
}

export async function getCatalog(options:any){
  productList.value = await listProducts(options)
}

export async function getFavorites(){
  favoriteList.value = []
  if (favorites.value && favorites.value.length>0) favoriteList.value = await listProducts({favorites: favorites.value})
}
export async function getTags(tag:any){
  productList.value = await listProducts({tag})
}
export async function getCategory(category:any, sub?:string){
   productList.value = await listProducts({category, sub})
}

export function openNamedProduct(name:string){
  selectedProduct.value = productList.value.filter((p:any)=>p.display_name==name)[0]
}
export function resetValues(){
    orderData.value = {}
    orderitems.value = []
    selectedProduct.value = false
    orderNote.value = null
    
}
export function resetCart(all = true){
    resetValues()
    //setCabinet(null)
    if (all) cart.value = []
    orderitems.value = [];
    orderQueUe.value = []
    
    setCatalog()
 }
 export function removeFromCart(item:any){
  state.value.cart = state.value.cart.filter((c:any)=>c.id!==item.id)
 }
 export function addToCart(product:any, quant:number=1,max:number=100000000){
  if (quant>max || product.x_purchase_ok === false) return false
  if (selectedStock.value) product.stock = {...selectedStock.value}
  const item = Object.assign(selectedStock.value || {}, {...product})
  if (item.x_batch && !item.extra_note) item.extra_note = 'Batch/lotnummer: ' + item.x_batch 
  if (selectedCatalog.value.value == 'Kastenscan' && !item.x_cabinet_id) return false
   if (!state.value.cart) state.value.cart || []
   if (!item.quantity) item.quantity = quant
   if (inCart(item)) {
      let result = false
      state.value.cart.filter((c:any)=>c.x_product_id==item.x_product_id).map((c:any)=>{
        if (c.quantity + item.quantity <= max) {
          c.quantity += item.quantity
          result = true
        }
      })
      return result
    }
  /* if (hasRole('Kastenscanner')) {
    state.value.cart.unshift(item)
   } else{*/
   state.value.cart.push(item)
   return true
 }

 export function addStockItemToCart(selectedStock:any, fn:Function = addToCart){
  if (!selectedStock.x_active_stock && selectedStock.x_bin_type == "top up") {
    return prompt("Top up", "Geef het huidige aantal op voor " + selectedStock.x_name + ', aanvullen tot ' + selectedStock.x_max + ' in vak.','0', "Opmerking", (response:any)=> {
      let q = selectedStock.x_max - parseInt(response.count)
      console.log(q)
      if (q > selectedStock.x_max) q = selectedStock.x_max
      if (q <1 ) return openToast(selectedStock.x_name + ' kon niet aangevuld worden met '+q+', max: ' + selectedStock.x_max, {duration:3000, color: 'warning'})
      selectedStock.quantity = q
      return fn(selectedStock,q)
    },
    [{
    name: "count",
    type: 'number',
    placeholder: 'Aantal',
    value: 1,
    min: 1
    }])
  } else {
    fn(selectedStock)
  }
 }
 export function removeFromOrderitems(item:any){
  state.value.items = state.value.items.filter((c:any)=>c.id!==item.id)
 }
 export async function addToOrderitems(product:any, replace=false){
  if (!product) return false
      const item = {...product}
      if (item.x_active_stock && item.x_stock_id && stockList.value) { //check stock
        const stock:any = await loadStockItem(item.x_stock_id)
        item.x_count = stock.count
        stockList.value.filter((s:any)=>s.x_stock_id==item.x_stock_id).map((s:any)=>s.x_count = stock.count)
      }
      delete item.ordered
    if (!state.value.items) state.value.items || []
   if (!item.quantity || !replace) item.quantity =1
  if (!item.x_active_stock) item.quantity = item.x_quantity || 1
   //if (item.quantity > item.x_count && scanType.value=='order') item.quantity = item.x_count
   if (!state.value.items) state.value.items = []
   if (state.value.items.filter((c:any)=>c.x_product_id==item.x_product_id && (!item.x_tht || item.x_tht == c.x_tht)).length>0) 
      return state.value.items.filter((c:any)=>c.x_product_id==item.x_product_id && (!item.x_tht || item.x_tht == c.x_tht)).map((c:any)=>{
    delete c.ordered
        if (replace) 
          c.quantity = item.quantity || 1
        else
          c.quantity += item.quantity || 1
        if (c.quantity > item.x_count && scanType.value=='order') {
          openToast(`U neemt ${c.quantity-1}x ${item.x_name} uit. Dit is meer dan de geregistreerde voorraad van ${item.x_count}.`, {duration: 3000, color: 'warning'})
          //c.quantity = item.x_count
        }
      })
      //if (hasRole('Kastenscanner')) {
      //  state.value.items.unshift(item)
      //}else{
        state.value.items.push(item)
      //}
   
 }

 export async function initCart(){
  
  state.value.cart = state.value.cart || []
  if (!state.value.cart.map) state.value.cart = []
  loadLocations(false,account.value)
  if (investmentList.value.length==0)  loadInvestments()
  initProcuration(account.value)
 }
 export async function getAccount(index:number){
  const ret = await getData('admin/account/list',{ids:[index]})
  return ret[0]
}
 export async function loadLocation(index:Number){
    let arr = await getData('order/deliveries',{type:'delivery', index})
    arr.map((l:any)=>{
      let code = l.building_area + l.building_floor
      if (l.building_room_number !=='0') code += '*' + l.building_room_number
      l.display_name = code + ' - ' + l.name
    })
    const location = arr[0] || {}
    if (location.drop_off_address_id) {
      arr = await getData('order/deliveries',{index:location.drop_off_address_id[0]})
      const doAddress = arr[0] || {}
      location.contact_address_complete = doAddress.contact_address_complete

    }
    return location
 }
 export async function loadLocations(admin = false, account?:Number){
  if (locationList.value.length==0 || admin || account) {
    const options:any = {parent: organisation.value.id,top:500, account}
    if (!isHospital.value) options.accounts = accountList.value.map((a:any)=>a.id)
    options.type = 'delivery'
    locationList.value = await getData('order/deliveries',options)
    locationList.value.map((l:any)=>{
      let code = l.building_area + l.building_floor
      if (l.building_room_number !=='0') code += ' ' + l.building_room_number
      l.name = code + ' - ' + l.name
    })
  }
 }

 export async function loadProjects(){
  projectList.value = await getData('order/projects',{hospital: user.value.organisation.id})
  return projectList.value
 }
 export async function loadInvestments(){
  
  investmentList.value = await getData('order/investments',{hospital: user.value.organisation.id, accounts:accountList.value.map((a:any)=>a.id) })
  return investmentList.value
 }
 export async function loadContracts(supplier:number){
  contractList.value = await getData('order/contracts',{hospital: user.value.organisation.id,supplier})
  return contractList.value
 }
 export async function initCatalog(page=1){
  console.log('top 30')
  selectedCabinet.value = null
  await getTop(10,true, page)
 // categories.value = await getData('catalog/categories')
  //if (suppliers.value.length==0) suppliers.value = await getData('order/suppliers',{})
 }
 export async function getTop(count:number,courant= false, page=1){
  const res =  await listProducts({top:count, courant, page},true)
  productList.value =(page > 1)  ? productList.value.concat(res): res
  return productList.value 
}

//initCatalog()
export async function getProductById(product:number){
  return await listProducts({product})
}
export async function getProduct(index:number,all = false){
   //if (productList.value && productList.value.filter) selectedProduct.value = productList.value.filter((p:any)=>p.id==index)[0]
   const product = await getItem('catalog/item',index,{hospital: all ? null : organisation.value.id}) 
   return product
}
export async function addProductToCartByName(name: string, quantity= 1) {
  const list:any = await listProducts({q:name},true)
  const p:any = list[0]
  if (!p) return openToast(`Product ${name} kan niet gevonden en toegevoegd worden aan uw winkelwagen.`, {duration: 5000, color: 'warning'})
  p.quantity = quantity
  console.log(p)
  addToCart(p)
}
export async function addProductToCartById(index: number, ref?:any) {
  let p:any = await getProduct(index)
  if (!p.id) return openToast('Product kan niet toegevoegd worden aan uw winkelwagen.', {duration: 2000, color: 'warning'})
  if(ref) p = Object.assign({...ref},p)
  p.quantity =  p.quantity || p.x_quantity
  addToCart(p)
}
let lastIndex:number
export async function loadProduct(index?:number,recurive=false){
  if (!index) return selectedProduct.value = null
 //if (lastIndex == index) return false
  //lastIndex = index
  if (selectedProduct.value && selectedProduct.value.id == index && selectedProduct.value.x_assortment && !selectedProduct.value.x_alternative_id) return selectedProduct.value
  if (selectedProduct.value && selectedProduct.value.x_product_id==index && selectedStock.value && selectedStock.value.x_product_id==index) {
  }else{
    invalidProduct.value = null
    selectedProduct.value = null
    selectedProduct.value  = getCartItem(index)
  }
  //if (selectedCabinet.value) selectedProduct.value = getStockItem(index)
  if (!selectedProduct.value) selectedProduct.value = await getProduct(index)
  //if (!selectedProduct.value || !selectedProduct.value.id) return selectedProduct.value = {}

  setStockQuantity(selectedProduct.value)
  if (!recurive && selectedProduct.value && selectedProduct.value.x_alternative_id) { //alternative
    if (selectedProduct.value.x_sale_ok){
      invalidProduct.value = await getProduct(selectedProduct.value.x_alternative_id)
    }else{
      const clone = {...selectedProduct.value}
      invalidProduct.value = JSON.parse(JSON.stringify(clone))
      selectedProduct.value = await getProduct(selectedProduct.value.x_alternative_id)
      //selectedProduct.value = Object.assign(clone,selectedProduct.value)
      setStockQuantity(selectedProduct.value)
    }
  }
  return selectedProduct.value
}


export function allowAdd(product = selectedProduct.value, force=false){
  if (!product) return false;
  //if (product && !hasTag(product.tags)) return false; //HACK 
  //if (product.x_alternative_id) return false;
  if (selectedCatalog.value.value == 'Kastenscan' && !product.x_cabinet_id && !selectedStock.value) return false
  //if (hasRole('Kastenscanner') && product.x_order_count>0 && !force) return false;
  if (product.x_sale_ok) return true;
  return false;
}


export const allowOrder = computed(()=>{
  //if (!checkOrder.value) return false
  if (!account.value) return false
  //console.log('has account')
  if (!location.value) return false
  //console.log('has location')
  if (loading.value) return false
  //console.log('not loading')
  if (!hasProcuration.value) return false
  //console.log('hasProcuration')
  if (needProcuration.value && procuration.value.length == 0) return false
  if (orderitems.value.length> 0) return false
  //console.log('Neeed and not procuration')
  //console.log('cleared', clearedProcuration.value)
  return clearedProcuration.value
})
let errorCount = 0

export async function orderAll(){
  if (orderQueUe.value.length==0) orderQueUe.value = [...orderCartList.value]
  
  errorCount = 0
  cart.value.map((o:any)=>o.ordered = false)
  const sendList = orderQueUe.value.filter((ocl:any)=>ocl.items.length>0)
  const ret = []
    for (let i in sendList)  {
       
        console.log('waiting', i);
        sendList[i].ordered = true
        ret.push(await processOrder(sendList[i]))
        if (i<sendList.length) await new Promise(resolve => setTimeout(resolve, 3000));
        //if (c==sendList.length) return []
      }
  orderQueUe.value = orderQueUe.value.filter(((ocl:any)=>!ocl.success))
  return ret
}
async function processOrder(ocl:any){
  if (!isConsole.value) showLoading('Uw bestelling wordt geplaatst voor ' + ocl.location_name,3000)
  //if (!isConsole.value) openToast('Bestelling wordt geplaatst voor ' + ocl.location_name, {duration:500, color: 'warning'})
  ocl.ordered = true
  const res:any = await orderCart(ocl)
  if (!res) {
    delete ocl.ordered
    errorCount++
    if (errorCount == 3) return openToast('Er is een fout opgetreden en uw bestelling is niet ingediend, neem contact op met de helpdesk', {duration:10000, color: 'danger'})
    return openToast('Deze bestelling kon nog niet ingediend worden probeer het nogmaals', {duration:5000, color: 'warning'})
  }
  cart.value = cart.value.filter((o:any)=>!o.ordered) //winkelwagen bijwerken
  hideLoading()
  ocl.success = true
  if (!isConsole.value) openToast('Bestelling succesvol ontvangen voor ' + ocl.location_name, {duration:1000, color: 'success'})
  return res
}
