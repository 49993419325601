import { ref, computed, watch } from "vue";
import { getData, getItem,setAction, saveData} from '@/plugins/OdooService';
import { account,location, setCatalog, orderitems, removeFromOrderitems, addToCart, preCartItemCount, addToOrderitems, orderAll, cart} from '@/plugins/shopService';
import { listAccountProcuration } from '@/plugins/procurationService';
import { qty2Order, getOrderCount} from '@/plugins/productService';
import { state, user, organisation, accountList, hasRole, isHospital} from '@/plugins/authService';
import { isConsole, toCurrency, moment, openToast, showConfirm, prompt,showLoading, hideLoading } from "@/plugins/interfaceControler";
import {scanType} from "@/plugins/scanControler";
import config from '@/config'

export const selectedCabinet = computed({
  get () {
    return state.value.cabinet
  },
  set (value) {
    state.value.cabinet = value
  }
})
if (selectedCabinet.value && !selectedCabinet.value.id) selectedCabinet.value = null
export const cabinetList:any = ref([])
export const stockList:any = ref([])
export const stockListCount:any = ref([])
export const inactiveStockList:any = ref([])

export const stockLogList:any = ref([])
export const selectedStock:any = ref()
export const showContent = ref(false)
export const searchInput = ref('')
export async function getStock(options: any,set:true){ //{q:string, tags:any=null}
  const stock = stockList.value.filter((sl:any)=>sl.x_cabinet_code == options.cabinet && sl.x_shelf == options.shelf && sl.x_bin == options.bin)[0]
  if (stock) return selectedStock.value = stock
  
  const res = await listStock(options)
  if (set) selectedStock.value = res[0]//setCabinet(res[0])
  return selectedStock.value
}
export function setStockQuantity(stock:any){
  if (stock.quantity > 1 ) return stock
  stock.quantity = stock.x_quantity || 1
  if (!selectedCabinet.value) return false
  if (!selectedCabinet.value.active_stock && stock.x_bin_type && config.stocktype[stock.x_bin_type] == 'Top Up' )  stock.quantity = stock.x_max - stock.x_count
}
export async function loadCabinet(index:number,set=false){
  if (selectedCabinet.value && selectedCabinet.value.id == index) return selectedCabinet.value
  selectedCabinet.value = await getCabinet(index)
  if (set) setCabinet(selectedCabinet.value, set)
  return selectedCabinet.value
}

export async function setCabinet(cab:any,reload=false){
  setCatalog('Kastenscan')
  if (!cab || !cab.id) {
    stockList.value = []
    selectedCabinet.value=null
    return false
  }
  selectedCabinet.value=cab
  if (!cab) return initCabinet()
  account.value = cab.customer_analytic_account_id[0]
  if (!reload && location.value ==cab.cabinet_location[0] && stockList.value.length>0) return true
  
  //listCabinetStock()
}

let prevLocation=0
export async function listLocationStock(loc = location.value, reload=false,sort?:String){
  if (!reload && (!loc || (location.value == loc &&  stockList.value.length>20))) return 
  if (selectedCabinet.value) showLoading(`Producten van de kasten in ${selectedCabinet.value.cabinet_location[1]} ophalen`)
  //console.log('listLocationStock')
  location.value = loc
  stockList.value =  await listStock({location: loc, top:3000, sort}) 
  //console.log('loaded')
  if (selectedCabinet.value)selectedCabinet.value.stock = stockList.value.filter((s:any)=>s.x_cabinet_id == selectedCabinet.value.id)
  hideLoading()
  await loadCabinetOrder(loc)
 
}

export async function loadCabinetOrder(location?:any, indexes?:any){
  
  stockListCount.value = await getData('order/lines/count',{location, state: 'sales', top:3000,indexes})
  stockList.value.map((s:any)=>s.x_order_count=0)
  stockList.value.filter((stock:any)=>{
    stockListCount.value.filter((ol:any)=>ol.x_cabinet_id==stock.x_cabinet_id && ol.x_product_id==stock.x_product_id).map((ol:any)=>{
      stock.x_order_count += ol.x_qty_to_deliver 
      stock.x_count =ol.x_count 
      //if (stock.x_product_id==380232) 
      //if (stock.x_product_id == 344573) console.log('ordered', stock.x_product_id, stock.x_order_count, stock.x_coun)
    })
  })
}


export async function listCabinetStock(cab = selectedCabinet.value, reload=false,sort?:String){
  if (!cab || !cab.cabinet_location) return 
  console.log('listCabinetStock')
  await listLocationStock(cab.cabinet_location[0],reload, sort)
  //if (!cab.stock || reload) cab.stock = await listStock({cabinet_id: cab.id}) 
  //stockList.value = cab.stock
}
export async function listOrderStock(cab = selectedCabinet.value, products=[]){
  stockList.value =  await listStock({cabinet_id: cab.id, products }) 
}

export async function setStockFromProduct(product:any){
  selectedStock.value = null
  if (!stockList.value) return false
  const pids:any = [product.id]
  if (product.product_tmpl_id) pids.push(product.product_tmpl_id[0])
  selectedStock.value = stockList.value.filter((sl:any)=>pids.includes(sl.x_product_id))[0]
}
export async function listCabinets(options: any={top:50}, load= true){ //{q:string, tags:any=null}
  options.hospital = organisation.value.name
  if (!isHospital.value) options.accounts = accountList.value.map((a:any)=>a.id)
  const ret = await getData('cabinet/list',options)
  if (!load) return ret
  if ( options.page>1) {
    cabinetList.value = cabinetList.value.concat(ret)
  } else  {
    cabinetList.value =  ret
  }
  return ret
}
export async function listStock(options: any, set=true){ //{q:string, tags:any=null}
  options.hospital = organisation.value.id
  if (!isHospital.value && !hasRole('Kastenbeheer')) options.accounts = accountList.value.map((a:any)=>a.id)
  const ret = await getData('cabinet/stock',options)
  if (set && options.page>1) {
    stockList.value = stockList.value.concat(ret)
  } else if (set) {
    stockList.value =  ret
  }
  return ret
}
export async function listInactiveStock(options: any, set=true){ //{q:string, tags:any=null}
  options.hospital = organisation.value.id
  if (!isHospital.value && !hasRole('Kastenbeheer')) options.accounts = accountList.value.map((a:any)=>a.id)
  options.archive = true
  const ret = await getData('cabinet/stock',options)
  if (set && options.page>1) {
    inactiveStockList.value = stockList.value.concat(ret)
  } else if (set) {
    inactiveStockList.value =  ret
  }
  return ret
}
export async function listStockLog(options: any){ //{q:string, tags:any=null}
  if (!options.cabinet && selectedCabinet.value) options.cabinet == selectedCabinet.value.id
  if (!options.stock && selectedStock.value) options.stock == selectedStock.value.id
  const ret = await getData('cabinet/stocklog',options)
  if (options.page>1) {
    stockLogList.value = stockLogList.value.concat(ret)
  } else {
    stockLogList.value =  ret
    
  }
  
  return ret
}
 
export async function initActiveCabinet(){
  //if (selectedCabinet.value) return await listStock({cabinet_id: selectedCabinet.value.id}) 
  await listCabinets({active:true, top: 10 })
}
export async function initCabinet(){
  
}

export async function saveItems(type='order'){
  
  showLoading((type=='order') ? `De voorraad wordt bijgewerkt` : `De voorraad wordt aangevuld`)
  if (selectedCabinet.value) setCabinet(selectedCabinet.value)
  setCatalog('Kastenscan',true)
  const count = preCartItemCount.value
  selectedStock.value = null
 /* if (type=='order') {
    orderitems.value.map((item:any)=>{
      const quantityToOrder = qty2Order(item)
      const ppp = {...item}
      ppp.quantity = quantityToOrder
      if (quantityToOrder>0) {
        addToCart(ppp,quantityToOrder)
      }
    })
  }*/

  do {
      const item = orderitems.value[0]
      if (!item.x_count) item.x_count = 0
      if (type=='order'){
        item.x_count -= item.quantity
        if (item.x_count <0) item.x_count = 0
      } else {
        item.x_count += item.quantity
      } 
     updateStock({
        id: item.x_stock_id, 
        count: item.x_count,
        log: type=='order' ? -item.quantity : item.quantity,
        log_type: type=='order' ? 'uitname' : (hasRole('Kastenbeheer') ? 'aanvullen' : 'terugleggen'),
        timestamp:  moment().format('YYYY-MM-DD HH:mm'),
        //active: item.x_count > 0 || !item.x_tht
      }) 
      stockList.value.filter((s:any)=>s.x_stock_id===item.x_stock_id).map((s:any)=>s.x_count = item.x_count)
      orderitems.value.shift();
    } while (orderitems.value.length>0)
  
   openToast(`U heeft succesvol ${count} items ${type == 'order' ? 'uitgenomen' : 'terugelegd'}`, {duration: 5000, color: 'success'})
   
    if (cart.value.length>0){
       if (!isConsole.value) openToast('Voorraad wordt aangevuld en besteld', {duration: 2000, color: 'success'})
           //await loadCummulativeOrders() //update open orders
           //await orderAll(true) // Auto order active Cabinets
    }
}



export async function saveItems_Old(type?:string){
  if (!type) type = scanType.value
  showLoading((type=='order') ? `De voorraad wordt bijgewerkt` : `De voorraad wordt aangevuld`)
  if (selectedCabinet.value) setCabinet(selectedCabinet.value)
  selectedStock.value = null
  const count = preCartItemCount.value
  let hasAll = await Promise.all(
    orderitems.value.map(async (item:any)=>{
      if (!item.x_count) item.x_count = 0
      if (type=='order'){
        //const quantityToOrder = qty2Order(item) || 0
        item.x_count -= item.quantity
        if (item.x_count <0) item.x_count = 0
        /*if (quantityToOrder>0) {
          addToCart(Object.assign({...item},{quantity:quantityToOrder, x_qantity:quantityToOrder}))
          if (!isConsole.value) openToast('Voorraad wordt aangevuld met ' + quantityToOrder, {duration: 2000, color: 'success'})
        }*/
      } else {
        item.x_count += item.quantity
      } 
      const ret = await updateStock({
        id: item.x_stock_id, 
        count: item.x_count,
        log: type=='order' ? -item.quantity : item.quantity,
        log_type: type=='order' ? 'uitname' : (hasRole('Kastenbeheer') ? 'aanvullen' : 'terugleggen'),
        timestamp:  moment().format('YYYY-MM-DD HH:mm'),
        active: item.x_count > 0 || !item.x_tht
      }) 
      await getXStock(item.x_stock_id)
      if (!ret.error) {
        item.ordered = true
       }else {
        item.ordered = false
        openToast('Fout bij het bijwerken van de voorraad van ' + item.display_name, {duration: 2000, color: 'danger'})
       }
      return item.ordered
    })
  )
  if (hasAll){
    orderitems.value = []
    if (stockList.value) stockList.value.map((s:any)=>{
      delete s.quantity
    })
     openToast(`U heeft succesvol ${count} items ${type == 'order' ? 'uitgenomen' : 'terugelegd'}`, {duration: 5000, color: 'success'})
      if (type=='order' && cart.value.length>0) { // && !hasRole('Kastenbeheer')
            //await loadCummulativeOrders() //update open orders
           //await orderAll(true) // Auto order active Cabinets
          
      }
    }
}


async function checkStock(product:any){
  const item = {...product} 
  let quantity = 0
  if (product.x_count <= product.x_min) quantity = item.x_max - item.x_count
  if (product.x_count + getOrderCount(product) <= product.x_min) {
    quantity = item.x_max - item.x_min  //virtual count
    if (quantity == 0) quantity = 1
  }
   if (quantity>0) { //add cart item
      if (item.x_sale_quantity && item.x_sale_quantity > 1) {
        const verp = parseInt(item.x_sales_uom.toUpperCase().replace('VERP',''))/item.x_sale_quantity
        console.log('verp', item.x_sales_uom.toUpperCase().replace('VERP',''), 'verp',verp)
        quantity = Math.round(quantity/verp)
      }
      
      addToCart(Object.assign({...item},{quantity, x_qantity:quantity}))
      if (!isConsole.value) openToast('Voorraad wordt aangevuld met ' + quantity, {duration: 2000, color: 'success'})
    }
}
export async function getStockItem(index:any){
  if (!stockList.value) return false
  let stock  = await stockList.value.filter((s:any)=>s.x_product_id == index)[0]
  if (!stock) stock = loadStockItem(index)
  return stock
}
export async function loadStockItem(index:any){
  const res = await getItem('cabinet/stock',index)
  return res
}
export function cabinetInfo(cab:any=selectedCabinet.value){
  if (!cab) return {}
  let shelfs=0, bins = 0, value= 0, count = 0, info= [], large = false
  if (!cab.stock) return {shelfs, bins, value, count, info: null}
  count = cab.stock.length
  if (cab.sterile) info.push("Steriel")
  if (cab.active_stock) info.push("Actieve kast")
  
  cab.stock.filter((s:any)=>s.x_cabinet_id==cab.id && !s.x_blocked).map((l:any)=>{
    l.index = l.id

    //if (shelfs.filter((s:any)=>s.shelf==l.x_shelf).length==0) shelfs.push({shelf: l.x_shelf, bins: []})
    //const item = shelfs.filter((s:any)=>s.shelf==l.x_shelf)[0]
    //item.bins.push(l.x_bin)
    if (l.x_shelf > shelfs && l.x_shelf < 100) shelfs = l.x_shelf
    if (l.x_bin > bins && l.x_bin < 100) bins = l.x_bin
    if (l.x_shelf > 100 || l.x_bin > 200) large = true
    if (cab.active_stock) {
      value += l.x_count * l.x_list_price
    }else if (l.x_bin_type == "2bin") {
      value += 1.5 * l.x_quantity * l.x_list_price
    } else if (l.x_bin_type == "topup") {
      value += l.x_count * l.x_list_price
    }else{
      value += 0.75 * l.x_max * l. x_list_price
    }
  })
  if (cab.customer_analytic_account_id) info.push(cab.customer_analytic_account_id[1])
  info.push("aantal: " + count)
  info.push("waarde: " + toCurrency(value))
  const matrix =Array.apply(null, Array(shelfs*bins)).map(function (x, i) { return i; }) 
  return {shelfs, bins, value, count, matrix, info: info.join(' | '), large}
}

export async function updateStock(stock: any){ //{q:string, tags:any=null}cabinet_location
  return await saveData('cabinet/stock', stock)
}
export async function updateCabinet(cabinet: any){ //{q:string, tags:any=null}cabinet_location
  return await saveData('cabinet', cabinet)
}
export async function updateStockCount(stock:any,quantity:number=1,log_type?:String, log_order_id?: Number){
    if (!stock) return false
    if (!stock.x_count) stock.x_count = 0
    stock.x_count += quantity
    if (stock.x_count<0) {
      stock.x_count = 0 
      openToast("Er is geen voorraad meer aanwezig",{duration:2000, color: 'warning'})
    }
    if (quantity < 0) checkStock(stock) //only check if chckout
    const ret = await updateStock({
        id: stock.x_stock_id,
        count: stock.x_count,
        log: quantity,
        log_type,
        log_order_id,
        timestamp:  moment().format('YYYY-MM-DD HH:mm')
      })
  
}
export async function updateStockTimestamp(id:any){
  if (!id) return false
  const ret = await updateStock({
      id,
      timestamp:  moment().format('YYYY-MM-DD HH:mm')
    })

}
export async function updateCabinetTimestamp(id:any){
  if (!id) return false
  const ret = await updateCabinet({
      id,
      timestamp:  moment().format('YYYY-MM-DD HH:mm')
    })

}

export async function getCabinet(index:any){
  const res = await getItem('cabinet/item',index)
  return res
}
export async function saveCabinet(record:any,select=false){
  //if (!record.id) return false

const data:any = (({ id, name, stage_id, active_stock, sterile, note, cabinet_code }) => ({ id, name, stage_id, active_stock, sterile, note, cabinet_code }))(record);
if (record.cabinet_location) data.cabinet_location = record.cabinet_location[0] || record.cabinet_location
if (record.customer_analytic_account_id) data.customer_analytic_account_id = record.customer_analytic_account_id[0] || record.customer_analytic_account_id
if (record.stage_id) data.stage_id = record.stage_id[0] || record.stage_id
if (data.id) { //Update
  if (!data.name || data.name =='') delete data.name
  if (!data.cabinet_code || data.cabinet_code =='') delete data.cabinet_code
  if (typeof data.sterile !='boolean') delete data.sterile
  if (typeof data.active_stock !='boolean') delete data.active_stock
  if ((data.cabinet_location[0] || data.cabinet_location) == 0 ) delete data.cabinet_location
  if ((data.customer_analytic_account_id[0] || data.customer_analytic_account_id) == 0 ) delete data.customer_analytic_account_id
}
const ret = await saveData('cabinet',data)
if (!ret.index) return false
const u = await getCabinet(record.id || ret.index)
if (select) selectedCabinet.value = u

if (u) {
    if (u && record.id) {
      
      cabinetList.value.map((c:any)=>{
            if (c.id==record.id) c = Object.assign(c, u  )
        })
    }else{
      cabinetList.value.push(u )
    }
  }

return true;
}

export async function clearCabinet(cab:any){
  const list = stockList.value.filter((l:any)=>l.x_cabinet_id && l.x_cabinet_id[0]==cab.id).map((l:any)=>l.id)
  if (list.length==0) return false
  setAction('cabinet/stock', 'action_archive', list)
  stockList.value = stockList.value.filter((l:any)=>!list.includes(l.id))
//stockList.value = stockList.value.filter((l:any)=>l.index!=st.index)
}
export async function cloneCabinet(cabinet:any = selectedCabinet.value){
 const lll = [...stockList.value.filter((s:any)=>s.x_cabinet_id == cabinet.id)] 
 cabinet = {...cabinet}
 delete cabinet.id
 cabinet.name += '(copy)'
 cabinet.cabinet_code += '-1'
 cabinet.stage_id = 1
 
 const res = await saveCabinet(cabinet,true)
  if (!res) return showConfirm('Fout',"Kast kan niet gekopieerd worden")
  //const res = await setAction('cabinet', 'copy', [cabinet.value.id])
 
  showConfirm('Kastinhoud',"Wilt u alle kastposities ook kopieren",function(){
    lll.map(async (item:any,i:number)=>{
      delete item.cabinet_id
      //console.log({...item})
      await addStockToCabinet(item)
      if (i==lll.length-1) {
        setTimeout(()=>{
          console.log('reload!!!!!')
          setCabinet(selectedCabinet.value,true)
        },1000)
        
      }
    })
    
  })
}

export async function addStockToCabinet(stock:any, cabinet=selectedCabinet.value){
  stock = await loadStockItem(stock.x_stock_id)
  // stock.cabinet_id = cabinet.id
  stock = (({ best_before,bin,blocked,cabinet_id,lotnumber,max,min,notes,product_id,quantity,shelf,type,customer_account_analytic_id, location,uom_txt }) => ({ best_before,bin,blocked,cabinet_id,lotnumber,max,min,notes,product_id,quantity,shelf,type,customer_account_analytic_id, location,uom_txt }))(stock);
  if (cabinet) stock.cabinet_id=cabinet.id
  stock.product_id = stock.product_id[0]
  if (stock.customer_account_analytic_id) {
    stock.customer_account_analytic_id = stock.customer_account_analytic_id[0] || stock.customer_account_analytic_id
  }else{
    stock.customer_account_analytic_id = false
  }
  //if (stock.customer_account_analytic_id <1) stock.customer_account_analytic_id = false
  const ret =  await saveStock(stock)//saveData('cabinet/stock', stock)
  return ret
}
export function allowAdd(st:any){
  return checkStockPlacement(st)
}

export async function saveStock(st:any,force=false){
  
  if (!allowAdd(st)) return false
  const index = st.id
  st = (({ id,bin,blocked,cabinet_id,lotnumber,best_before,max,min,notes,product_id,quantity,shelf,type,count,customer_account_analytic_id,timestamp, location,uom_txt, log, log_type }) => ({id,bin,blocked,cabinet_id,lotnumber,best_before,max,min,notes,product_id,quantity,shelf,type,count,customer_account_analytic_id,timestamp, location,uom_txt, log, log_type }))(st);
  if (selectedCabinet.value && !st.cabinet_id) st.cabinet_id=selectedCabinet.value.id
  if (st.product_id[0]) st.product_id = st.product_id[0]
  if (st.cabinet_id[0])st.cabinet_id = st.cabinet_id[0]
  if (st.customer_account_analytic_id) {
    st.customer_account_analytic_id = st.customer_account_analytic_id[0] || st.customer_account_analytic_id
  } else{
    st.customer_account_analytic_id = false
  }
 // if (st.customer_account_analytic_id <1) st.customer_account_analytic_id = false
  const ret =  await saveData('cabinet/stock', st)
  if (!ret.index && ret!==true) return false
  const u:any = getXStock(index || ret.index)
  if (!stockList.value) stockList.value =[]
  if (stockList.value.filter((s:any)=>s.x_stock_id==u.x_stock_id).length==0){
   stockList.value.unshift(u)
    
  }
  return u
}

async function getXStock(index:number){
  const stock = await getItem('cabinet/xstock',index)
  stockListCount.value.filter((ol:any)=>ol.x_cabinet_id==stock.x_cabinet_id && ol.x_product_id==stock.x_product_id).map((ol:any)=>{
      stock.x_order_count +=ol.x_qty_to_deliver 
  })

  if (!stockList.value) stockList.value =[]
  let updt = false
  stockList.value.filter((c:any)=>c.x_stock_id == index).map((c:any)=>{
            c = Object.assign(c, stock  )
            updt = true
        })
  if (!updt)  stockList.value.unshift(stock)
  return stock
}

export function deleteStock(stock:any,confirm=false){
  if (!stock) return null
  const index = stock.x_stock_id || stock.id
  if (!index) return null
  const fn =  async function () { const ret = await setAction('cabinet/stock', 'action_archive', [index])
        console.log(ret)
        selectedStock.value = null
        if (stockList.value) stockList.value = stockList.value.filter((c:any)=>c.x_stock_id!=index)
        if (selectedCabinet.value)selectedCabinet.value.stock = stockList.value.filter((s:any)=>s.x_cabinet_id == selectedCabinet.value.id)
      }
  if (confirm) return showConfirm('Kastpositie verwijderen',`Wilt u ${stock.display_name} uit de kast verwdijeren`,fn)
  fn()
 
}

export function checkStockProduct(product:any){
  if (!selectedCabinet.value) return true
  if (product.x_sterile && !selectedCabinet.value.sterile) showConfirm('Waarschuwing',"Let op: u voegt een steriel product in een niet steriele kast")
  if (!product.x_sterile && selectedCabinet.value.sterile) showConfirm('Waarschuwing',"Let op: u voegt een niet steriel product in een steriele kast")
  return true
}

export function checkStockPlacement(stock:any, allow= false){
  let cabinet_id = stock.cabinet_id || stock.x_cabinet_id
  if (!cabinet_id && selectedCabinet.value) cabinet_id = selectedCabinet.value.id
  if (stock.blocked || !cabinet_id) return true
  const res:any = stockList.value.filter((l:any)=>
    (l.x_cabinet_id == cabinet_id) &&
    (l.x_stock_id!==stock.id || !stock.id )
    && !l.x_blocked 
    && l.x_shelf > 0
    && l.x_shelf==stock.shelf 
     && l.x_bin==stock.bin
     && (!stock.best_before || l.x_tht==stock.best_before)
     && (!stock.lotnumber || l.x_batch==stock.lotnumber)
    )
  if (stock._error && stock.blocked) stock.blocked = false
  stock._error = false
  if (res.length>0) {
    stock.blocked = true
    stock._error = true
    openToast("Deze bin is al gevuld met " + res[0].display_name)
    return !allow
    //if (!stock.lotnumber) return false
    //return showConfirm('Waarschuwing',"Deze bin is al gevuld met " + res[0].product_id[1])
  }
  const ret1 = stockList.value.filter((l:any)=>(l.x_stock_id!==stock.id || !stock.id ) && l.x_template_id==stock.product_id && l.x_cabinet_id == selectedCabinet.value.id)
  if (ret1.length>0) {
    showConfirm('Waarschuwing',ret1[0].display_name + " is al aanwezig in de kast op plank " + ret1[0].x_shelf + ' vak ' + + ret1[0].x_bin)
    if (!ret1[0].x_blocked)return false
  }
  /*if (product && !product.purchase_ok && !product.alternative_product_ids) return showConfirm('Waarschuwing',"Dit product kan niet ingekocht worden. Wilt u deze toch toevoegen?", async function(){
     fn(cab, stock, product)
   })
   fn(cab, stock, product)*/
  return true
}
const cInfo:any = ref(cabinetInfo())

export async function addProductToBin(product:any,shelf=cInfo.value.shelfs+1,bin=1){
  if (!selectedCabinet.value || !hasRole('Kastenbeheer')) return false
//  const fn = async ()=>{
      const ref= stockList.value.filter((s:any)=>s.x_cabinet_id == selectedCabinet.value.id)[0] || {}
      if (!product.x_batch && !checkStockProduct(product)) return false
      const stock:any = await saveStock({
        cabinet_id: selectedCabinet.value.id,
        type: ref.x_bin_type || (selectedCabinet.value.active_stock ? 'top up' : '2bin'),
        product_id: product.x_template_id,
        lotnumber: product.x_batch,
        best_before:  product.x_tht ? moment(product.x_tht,'DD-MM-YYYY').format('YYYY-MM-DD') : null,
        count:1,
        quantity:1,
        shelf,
        bin
      },true)
      return stock
      //stockList.value = stockList.value.sort(compare)
      
    //}
   
   // return await fn()
    //showConfirm("Product toevoegen", `wilt u <b>${product.display_name} <br>${product.x_batch? product.x_batch :''} ${product.x_tht ? product.x_tht : ''}</b> toevoegen aan deze kast?`,fn)
}
function compare( a:any, b:any ) {
  if (a.x_shelf != b.x_shelf) return a.x_shelf ? 1 : -1
  if (a.x_bin == b.x_bin) return 0
  return a.x_bin < b.x_bin ? -1 : 1
}
export async function addProductCount(ppp:any,result:any={}){
  if (!selectedCabinet.value || !hasRole('Kastenbeheer')) return false
  if (!selectedCabinet.value.active_stock) return openToast('Dit product is al aanwezig in deze kast')
  const product = {...ppp}
  const stock = stockList.value.filter((st:any)=>st.id == product.id)[0]
  const arr = [product.display_name]
  
  if (product.x_batch) arr.push(product.x_batch)
  if (product.x_tht) arr.push(product.x_tht)
  if (product.x_active_stock) return addToOrderitems(product,true)
  if (scanType.value == 'order') {
    return prompt("Product uitnemen", arr.join(', ') + ' \naantal:','0', "Aantal", async (response:any)=> {
      //updateStockCount(stock, -parseInt(response.count))
        product.quantity = parseInt(response.count)
      addToOrderitems(product,true)
    
    },[{
        name: "count",
        type: 'number',
        placeholder: 'Aantal',
        value: result.quantity || 1,
        min: 1
        }])  
  }
  prompt("Artikel aanvullen", arr.join(', ') + ': \naanvullen met:','0', "Aantal", async (response:any)=> {
    product.quantity = parseInt(response.count)
    addToOrderitems(product, true)
    //updateStockCount(stock, parseInt(response.count))
    
  },[{
      name: "count",
      type: 'number',
      placeholder: 'Aantal',
      value: result.quantity || 1,
      min: 1
      }])
}
export function stockUnit(stock:any) {
  if (stock.x_quantity==1) return 'stuk'
  if (!stock.x_stock_unit || stock.x_stock_unit =='ST') return stock.x_quantity + ' stuks'
  if (stock.x_stock_unit) return stock.x_stock_unit + '(' + stock.x_quantity  + ' st)'
  return 'stuk'
}

export function extractUnit(stock:any, unit=false) {
  let verp = parseInt(stock.x_sales_uom.toUpperCase().replace('VERP',''))
  if (parseInt(stock.x_quantity) > 0) 
    verp =  parseInt(stock.x_sales_uom.toUpperCase().replace('VERP',''))/parseInt(stock.x_quantity)
  if (verp == 1) return (unit? 'stuk' : '')
  return verp + (unit? ' st' : '')
}
