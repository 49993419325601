import { ref, computed, nextTick } from "vue";
import { getData, getItem, saveData, setAction} from '@/plugins/OdooService';
import { state, user, organisation, hasRole, isHospital, accountList as aList, authenticated} from '@/plugins/authService';
import { theme, setTheme, openToast, showLoading, hideLoading,prompt} from '@/plugins/interfaceControler';
import {  projectList,  productGroup} from '@/plugins/shopService';
import { assortList} from '@/plugins/siteService';
import { trasformSQLList, postMessage} from '@/plugins/procurationService';
import {listCabinets, cabinetList, setCabinet, selectedCabinet} from '@/plugins/cabinetControler';
import config from '@/config';

export const todoList:any = ref([])
export const accountList:any = ref([...aList.value])
export const locationList:any = ref([])
export const dropoffList:any = ref([])
export const dropOff:any=ref()
export const location:any=ref()
export const account:any=ref()
export const selectedUser:any=ref()
export const selectedType:any=ref()

export const isMine = computed({
  get () {
    return selectedUser.value && selectedUser.value.id == user.value.id
  },
  set (value) {
    selectedUser.value = (value === true ) ? user.value: null
  }
})
export const contactList:any = ref([])
export const userList:any = ref([])
export const roleList:any = ref([])
export const proleList:any = ref(config.procurationroles)

export const procurationList:any = ref([])
export const productRangeList:any = ref([])
export async function refreshCatalog(){
  showLoading('Opnieuw laden van de catalogus tabel...')
  await getData('sql/refresh/1')
  hideLoading()
  openToast(`De catalogusijst is bijgewerkt`, {duration: 3000, color: 'success'})
}
export async function sendReminders(order=0){
  prompt("Reminders versturen TEST", "Geef het test E-mail adres op",user.value.email.replace('zxl.nl','zorgservicexl.nl'), "E-mailadres", async (response:any)=> {
    nextTick()
    showLoading('Reminders worden verstuurd',5000)
    const res = await getData(`procuration/reminder/${order}/` + response.description)
    openToast(`Er zijn ${res.length} herinneringen verstuurd`, {duration: 2000, color: res.length>0 ? 'success' : 'warning'})
    hideLoading()
  })
 
}
export async function initAdmin(){
  if (hasRole('Beheerder') || hasRole('Kastenbeheer') ) await listAccounts({})
  //ret = await getData('admin/account/ranges',{})
  //productRangeList.value = ret.sort(compare)
  if (hasRole('Beheerder')) {
    if (userList.value.length==0) await listUsers()
    await listProcuration({},true)
  }
  if (roleList.value.length==0) await userRoles()
  productGroup.value = []
  if (locationList.value.length==0) await loadLocations({},true)
      if (hasRole('Beheerder')){
        userList.value.map((u:any)=>{
            const pp = procurationList.value.filter((p:any)=>p.user_id[0]==u.id)
            u.accounts = pp.filter((p:any)=>p.customer_analytic_account_id).map((p:any)=> p.customer_analytic_account_id[0])
            u.proles = []
            u.admin = false
            pp.map((p:any)=> {
              if (!u.proles.includes(p.user_role) ) {
                u.admin = p.user_role == 'Medisch'
                u.proles.push(p.user_role == 'Medisch' ? p.name + ' expertlid' : p.user_role)
              }
            })
            u.admin = u.admin || u.partner_role_ids.includes(2) || u.accounts.length>50 
            u.roles = roleList.value.filter((l:any)=>u.partner_role_ids.includes(l.id)).map((l:any)=>l.name).join(', ')
            return u
        })
        procurationList.value.map((p:any)=>{
            const user = userList.value.filter((u:any)=>p.user_id[0]==u.id)[0]
            if (user) p.roles = user.roles
            return p
        })
        accountList.value = accountList.value.map((a:any)=>{
                        a.count= procurationList.value.filter((p:any)=>p.customer_analytic_account_id[0]==a.id).length
                        a.active = procurationList.value.filter((p:any)=>p.customer_analytic_account_id[0]==a.id && p.user_role=='Budgethouder').length>0
                        a.subname = procurationList.value.filter((p:any)=>p.customer_analytic_account_id[0]==a.id).map((p:any)=>p.user_role).join(', ')
                       return a
        })
    }
    if (hasRole('Kastenbeheer')) {
            await listCabinets()
            locationList.value.map((l:any)=>{
                l.count = cabinetList.value.filter((c:any)=>c.cabinet_location && c.cabinet_location[0]==l.id).length
            })
            //return setView('cabinets')
    }
}

export async function setHospital(organisation:any){
  dropoffList.value = []
  console.log('setHospital')
  if (!state.value.user.hospital_ids || !state.value.user.hospital_ids.includes(organisation.id) && state.value.user.organisation.id!=organisation.id ) return false
  state.value.organisation = organisation || state.value.user.organisation
  accountList.value = []
  
  aList.value = await listAccounts()
  if (aList.value.length == 0) aList.value = []
  locationList.value= []
  //dropoffList.value = []
  projectList.value= []
  productGroup.value = []
  setTheme( state.value.organisation)
}
export async function listMutations(object:String,index:Number, field=''){
  const params = [object,index,field]
  const res = await getData('admin/mutation/' + params.join('/'))
  return res
}
export async function listTodo(options:any={}){
  options.hospital = organisation.value.name
  todoList.value = await getData('procuration/todo',options)
}
export async function listAccounts(options:any = {top:750}, load=true){
  console.log(isHospital.value)
  if (!isHospital.value) {
    accountList.value = aList.value
    return aList.value
  }
  options.hospital =  organisation.value.name
  let ret =  await getData('admin/account/list',options)
  if (!load) return ret
  if ( options.page>1) {
    accountList.value = accountList.value.concat(ret)
  } else  {
    accountList.value =  ret
  }
  return accountList.value
}

export async function selectCabinet(cab:any){
  let ret
  if (selectedCabinet.value && cab && selectedCabinet.value.id == cab.id) return false
  setCabinet(cab)
  if (!cab) return false
  if (cab.customer_analytic_account_id) {
    ret = await listAccounts({ids: [cab ? cab.customer_analytic_account_id[0]: null]},false)
    account.value = ret[0]
  }
  if (cab.cabinet_location){
    ret = await loadLocations({ids: [cab.cabinet_location ? cab.cabinet_location[0]: 0]},false)
    location.value = ret[0]
  }
}

export async function loadLocations(options:any = {top:30}, load=true){
  if (!isHospital.value) options.accounts = aList.value.map((a:any)=>a.id)
  options.hospital =  organisation.value.name
  options.parent =  organisation.value.id
  // options.parent = organisation.value.id
  let ret =  await getData('order/deliveries',options)
  //let ret =  await getData('order/deliveries',options)
  ret.filter((l:any)=>l.building_area).map((l:any)=>{
    let code = l.building_area + l.building_floor
    if (l.building_room_number !=='0') code += ' ' + l.building_room_number
    l.reference = code
  })
  if (!load) return ret
  if ( options.page>1) {
    locationList.value = accountList.value.concat(ret)
  } else  {
    locationList.value =  ret
  }
  
    return locationList.value
}
export async function loadDropoffs(options:any = {top:30}, load=true){
  if (dropoffList.value.length>0) return dropoffList.value
  options.hospital =  organisation.value.name
  options.parent = organisation.value.id
  
  let ret =  await getData('order/deliveries',options)
  //let ret =  await getData('order/deliveries',options)
  ret.filter((l:any)=>l.building_area).map((l:any)=>{
    let code = l.building_area + l.building_floor
    if (l.building_room_number !=='0') code += ' ' + l.building_room_number
    l.reference = code
  })
  organisation.value.location_count = ret.length
  options.type = 'delivery'
  const p = await Promise.all(
    ret.map(async (drop:any)=>{
      options.dropoff = drop.id
      options.indexes = true
      let locs =  await getData('order/deliveries',options)
      drop.location_ids = locs.map((l:any)=>l.id)
      drop.location_count = drop.location_ids.length
    }))
  dropoffList.value =  ret
 
  dropoffList.value.push(organisation.value)
  return dropoffList.value
}
export async function userRoles(){
    if (roleList.value.length > 0 || !authenticated.value)   return false
    const ret = await getData('admin/account/list/roles',{})
    roleList.value =  ret.sort(compare)
}
userRoles()

export async function getUser(index:number){
  //if (userList.value.length > 0)   return false
  const user = await getItem('admin/profile',index)
  user.partner_id = [user.id,user.name]
  user.id = index
  return user
}
export async function listUsers(options:any={}, load=true){
  //if (userList.value.length > 0)   return false
  options.parent = organisation.value.id
  const ret =  await getData(options.roles || hasRole("Beheerder") ? 'admin/contact/list' : 'procuration/user/list',options)
  if (!load) return ret
  if ( options.page>1) {
    userList.value = userList.value.concat(ret)
  } else  {
    userList.value =  ret
  }
  return userList.value
}
export async function listContacts(options:any={}, load=true){
  //if (userList.value.length > 0)   return false
  options.parent = organisation.value.id
  const ret =  await getData('admin/contact/list',options)
  if (!load) return ret
  if ( options.page>1) {
    contactList.value = userList.value.concat(ret)
  } else  {
    contactList.value =  ret
  }
  return contactList.value
}
export async function refreshProcuration(account:any=null){
  procurationList.value = await listProcuration({account})
}
export async function listProcuration(options:any=null, load=true){
  
  options.hospital = organisation.value.id
  if (!options.account && !options.user && !options.all) options.roles = config.gatekeeper
  options.top = 50
  let ret =  await getData('procuration/list',options)
  ret = trasformSQLList(ret)
  if (load) procurationList.value = ret
  return ret
}
export async function updateContact(upd:any){
  const ret = await saveData('admin/contact',upd)
  return ret
}
export async function updateUser(upd:any){
  const index= upd.id
  const ret = await saveData('admin/user',upd)
  postMessage('res.users',index || ret, 'Gebruiker aangepast')
  return ret
}
export async function deleteUser(data:any){
  await getData('admin/procuration/remove/' + data.id)
  return await updateUser({id:data.id, active:false})
}
export async function saveUser(data:any){
      
      let res = await updateContact({
          id: data.partner_id ? data.partner_id[0] : null,
          type: "contact",
          parent_id: organisation.value.id,
          name: data.name,
          email: data.email ? data.email.toLowerCase() : null,
          assortment_ids: data.assortment_ids,
          partner_role_ids: data.partner_role_ids,
          procuration: data.procuration,
          hospital_ids: data.hospital_ids
      })
      const pId = data.partner_id ? data.partner_id[0] : res
      postMessage('res.partner',pId, data.partner_id ? 'Gegevens gewijzigd' : 'Contactpersoon aangemaakt')
      if (res) {
          const usr:any = {
          id: data.id,
          partner_id: pId,
          login: data.email ? data.email.toLowerCase() : null,
          notification_type: "email"
          }
          if (data.password && data.password.length >6 && hasRole("Beheerder") || usr.id == user.value._id ) usr.password = data.password
          if (!data.id) {
              usr.share = true
              usr.sel_groups_1_10_11 = 10 //portal user
          }
          res = await updateUser(usr)
      }
      return res
  }
export async function updateDelivery(upd:any){
  if (upd.customer_analytic_account_ids)upd.customer_analytic_account_ids = [
    [
        6,
        false,
        upd.customer_analytic_account_ids
    ]
  ]
  const ret = await saveData('admin/partner',upd)
  return ret
}
export async function updateProcuration(proc:any){
  //const roleid = proleList.value.filter((r:any)=>r.name == role)[0].id
  //proc.user_role = 14
  const index = proc.id
  const ret = await saveData('admin/procuration',proc)
  postMessage('procuration',index, 'Procuratie aangepast')
  return ret

}
export async function getProfile(index:number){
  const ret = await getItem('admin/profile',index)
  return ret

}

export async function updateContactField(field:string, value:any, usr = user.value){
  const upd:any = {id: usr.id}
  upd[field] = value
  const ret = await updateContact(upd)
  return ret
}
export async function updateUserField(field:string, value:any, usr = user.value){
  const upd:any = {id: usr.id}
  upd[field] = value
  const ret = await updateUser(upd)
  return ret

}

export async function archive(model:string, index:number){
  await setAction(model, 'action_archive', [index])
}

function compare( a:any, b:any ) {
  if ((a.name || a.x_name) == (b.name || b.x_name)) return 0
  return (a.name || a.x_name) < (b.name || b.x_name) ? -1 : 1
}


